var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Tags Basic"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Tags are added by clicking the")]), _c('code', [_vm._v("Add")]), _c('span', [_vm._v(" button, pressing the")]), _c('code', [_vm._v("Enter")]), _c('span', [_vm._v(" key or optionally when the")]), _c('code', [_vm._v("change")]), _c('span', [_vm._v(" event fires on the new tag input.")])]), _c('div', [_c('label', {
    attrs: {
      "for": "tags-basic"
    }
  }, [_vm._v("Type a new tag and press enter")]), _c('b-form-tags', {
    staticClass: "mb-2",
    attrs: {
      "input-id": "tags-basic"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('b-card-text', [_vm._v("Value: " + _vm._s(_vm.value))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }