var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Tag validation"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeValidation) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("You can optionally provide a tag validator method via the ")]), _c('code', [_vm._v("tag-validator")]), _c('span', [_vm._v(" prop.")])]), _c('div', [_c('b-form-group', {
    attrs: {
      "state": _vm.state,
      "label": "Tags validation example",
      "label-for": "tags-validation"
    },
    scopedSlots: _vm._u([{
      key: "invalid-feedback",
      fn: function fn() {
        return [_vm._v(" You must provide at least 3 tags and no more than 8 ")];
      },
      proxy: true
    }, {
      key: "description",
      fn: function fn() {
        return [_c('div', {
          attrs: {
            "id": "tags-validation-help"
          }
        }, [_vm._v(" Tags must be 3 to 5 characters in length and all lower case. Enter tags separated by spaces or press enter. ")])];
      },
      proxy: true
    }])
  }, [_c('b-form-tags', {
    attrs: {
      "input-id": "tags-validation",
      "input-attrs": {
        'aria-describedby': 'tags-validation-help'
      },
      "state": _vm.state,
      "tag-validator": _vm.tagValidator,
      "separator": " "
    },
    model: {
      value: _vm.tags,
      callback: function callback($$v) {
        _vm.tags = $$v;
      },
      expression: "tags"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }