var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Styling Options"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeStyling) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Several props are available to alter the basic styling of the default tagged interface:")]), _c('code', [_vm._v("tag-pills, tag-variant, size, placeholder, state, disabled")])]), _c('div', [_c('label', {
    attrs: {
      "for": "tags-pills"
    }
  }, [_vm._v("Enter tags")]), _c('b-form-tags', {
    staticClass: "mb-2",
    attrs: {
      "input-id": "tags-pills",
      "tag-variant": "primary",
      "tag-pills": "",
      "size": "lg",
      "separator": " ",
      "placeholder": "Enter new tags separated by space"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('b-card-text', [_vm._v("Value: " + _vm._s(_vm.value))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }