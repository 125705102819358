var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Detecting new, invalid, and duplicate tags"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDetecting) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("The event ")]), _c('code', [_vm._v("tag-state")]), _c('span', [_vm._v("will be emitted whenever new tags are entered into the new tag input element, tags that do not pass validation, or duplicate tags are detected. The event handler will receive three arrays as its arguments: ")]), _c('code', [_vm._v("validTags, invalidTags , duplicateTags ")])]), _c('div', [_c('label', {
    attrs: {
      "for": "tags-state-event"
    }
  }, [_vm._v("Enter tags")]), _c('b-form-tags', {
    attrs: {
      "input-id": "tags-state-event",
      "tag-validator": _vm.validator,
      "placeholder": "Enter tags (3-5 characters) separated by space",
      "separator": " "
    },
    on: {
      "tag-state": _vm.onTagState
    },
    model: {
      value: _vm.tags,
      callback: function callback($$v) {
        _vm.tags = $$v;
      },
      expression: "tags"
    }
  }), _c('b-card-text', {
    staticClass: "mt-2"
  }, [_vm._v(" Tags: " + _vm._s(_vm.tags) + " ")]), _c('b-card-text', [_vm._v("Event values:")]), _c('ul', [_c('li', [_vm._v("validTags: " + _vm._s(_vm.validTags))]), _c('li', [_vm._v("invalidTags: " + _vm._s(_vm.invalidTags))]), _c('li', [_vm._v("duplicateTags: " + _vm._s(_vm.duplicateTags))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }