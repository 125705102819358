var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Advanced custom rendering usage"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAdvanceCustom) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("In situations where the ")]), _c('code', [_vm._v("inputHandlers")]), _c('span', [_vm._v(" will not work with your custom input, or if you need greater control over tag creation, you can take advantage of the additional properties provided via the default slot's scope. ")])]), _c('div', [_c('b-form-group', {
    attrs: {
      "label": "Tagged input using dropdown"
    }
  }, [_c('b-form-tags', {
    attrs: {
      "no-outer-focus": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var tags = _ref.tags,
            disabled = _ref.disabled,
            addTag = _ref.addTag,
            removeTag = _ref.removeTag;
        return [tags.length > 0 ? _c('ul', {
          staticClass: "list-inline d-inline-block mb-1"
        }, _vm._l(tags, function (tag) {
          return _c('li', {
            key: tag,
            staticClass: "list-inline-item"
          }, [_c('b-form-tag', {
            attrs: {
              "title": tag,
              "disabled": disabled,
              "variant": "primary"
            },
            on: {
              "remove": function remove($event) {
                return removeTag(tag);
              }
            }
          }, [_vm._v(" " + _vm._s(tag) + " ")])], 1);
        }), 0) : _vm._e(), _c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary",
            "block": "",
            "menu-class": "w-100"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "LinkIcon"
                }
              }), _c('span', {
                staticClass: "ml-25"
              }, [_vm._v("Choose tags")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-form', {
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return function () {}.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          staticClass: "mb-0",
          attrs: {
            "label-for": "tag-search-input",
            "label": "Search tags",
            "label-cols-md": "auto",
            "label-size": "sm",
            "description": _vm.searchDesc,
            "disabled": disabled
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "tag-search-input",
            "type": "search",
            "size": "sm",
            "autocomplete": "off"
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })], 1)], 1), _c('b-dropdown-divider'), _vm._l(_vm.availableOptions, function (option) {
          return _c('b-dropdown-item', {
            key: option,
            on: {
              "click": function click($event) {
                return _vm.onOptionClick({
                  option: option,
                  addTag: addTag
                });
              }
            }
          }, [_vm._v(" " + _vm._s(option) + " ")]);
        }), _vm.availableOptions.length === 0 ? _c('b-dropdown-text', [_vm._v(" There are no tags available to select ")]) : _vm._e()], 2)];
      }
    }]),
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }